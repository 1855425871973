const bodyWrap = document.querySelector("body");
const menuToggle = document.querySelector(".site-menu-toggle");
const closeMenu = document.querySelector(".close-menu");
const hamburger = document.querySelector(".hamburger");
const menuMobile = document.querySelector(".mobile-nav-wrap");
const backTop = document.querySelector(".back-to-top");
// const ticketToggle = document.querySelector(".ticket-toggle");
// const ticketModal = document.querySelector(".booking-ticket ");
const counterItem = document.querySelectorAll(".counter");
const setBackground = document.querySelectorAll("[setBackground]");
// const closeTicketModal = document.querySelector(".booking-ticket .close-modal");
const cartToggle = document.querySelector(".shopping-cart-toggle");
const cartWrap = document.querySelector(".shopping-cart-wrap");

document.addEventListener("DOMContentLoaded", () => {
	mappingFunc();
	swiperInit();
	generalFunction.init();
});

window.addEventListener("resize", function () {
	let width = this.window.innerWidth;
	if (width <= 1200.1) {
		mappingFunc();
		hideElement();
	}
});
window.addEventListener("scroll", function () {
	const width = this.window.innerWidth;
	const nav = document.querySelector(".sidenav-group");
	const scrollTop = window.scrollY || document.documentElement.scrollTop;

	if (width >= 1200.1) {
		if (
			document.body.scrollTop > 100 ||
			document.documentElement.scrollTop > 100
		) {
			$("body").addClass("minimize");
		} else {
			$("body").removeClass("minimize");
		}
	}

	if (scrollTop > window.innerHeight) {
		nav.classList.add("show");
	} else {
		nav.classList.remove("show");
	}
});
document.addEventListener("click", (event) => {
	if (
		!cartToggle.contains(event.target) &&
		!cartWrap.contains(event.target)
	) {
		cartWrap.classList.remove("open");
	}
	// if (!event.target.closest(".booking-ticket, .ticket-toggle")) {
	// 	ticketModal.classList.remove("is-open");
	// }

	if (!event.target.closest(".mobile-nav-wrap, .site-menu-toggle")) {
		menuMobile.classList.remove("is-open");
		bodyWrap.classList.remove("overlay-bg");
		hamburger.classList.remove("is-active");
	}
});

Fancybox.bind("[data-fancybox]", {
	parentEl: document.body[0], // Element containing main structure
});

function hideElement() {
	if ($(window).width() < 1200.1) {
		const [search, menu] = [".searchbox", ".nav-primary-menu"].map(
			(selector) => document.querySelector(selector)
		);

		[search, menu].forEach((el) => (el.style.display = "flex"));
	}
}

const generalFunction = {
	toggleFunc: () => {
		menuToggle.addEventListener("click", () => {
			menuMobile.classList.toggle("is-open");
			hamburger.classList.toggle("is-active");
			bodyWrap.classList.toggle("overlay-bg");
		});

		// ticketToggle.addEventListener("click", () => {
		// 	ticketModal.classList.toggle("is-open");
		// });
		// closeTicketModal.addEventListener("click", () => {
		// 	ticketModal.classList.remove("is-open");
		// });
		if (closeMenu !== null) {
			closeMenu.addEventListener("click", () => {
				menuMobile.classList.remove("is-open");
				hamburger.classList.remove("is-active");
				bodyWrap.classList.remove("overlay-bg");
			});
		}
	},
	backTop: () => {
		backTop.addEventListener("click", (event) => {
			event.preventDefault();
			$("html, body").animate({ scrollTop: 0 }, "300");
		});
	},
	tabFunction: () => {
		$(".tab-nav li a").on("click", function () {
			$(this).parents(".tab-nav").find("li").removeClass("active");
			$(this).parents("li").addClass("active");

			var display = $(this).attr("data-type");
			$(this).parents("section").find(".tab-item").removeClass("active");
			$("#" + display).addClass("active");
		});
	},
	dropdownMenu: () => {
		if ($(window).width() < 1200.1) {
			$(".drop-down .title em").on("click", function () {
				if ($(this).parents(".drop-down").hasClass("is-open")) {
					$(".drop-down .nav-sub").slideUp();
					$(".drop-down").removeClass("is-open");
				} else {
					$(".drop-down .nav-sub").slideUp();
					$(".drop-down").removeClass("is-open");
					$(this).parent().next().slideDown();
					$(this).parents(".drop-down").addClass("is-open");
				}
			});
			$(".tertiary-menu-toggle").on("click", function () {
				$(".nav-tertiary-menu").slideToggle();
			});
		}
	},

	counterAnimate: () => {
		if (counterItem !== null) {
			if (counterItem.length) {
				const counterUp = window.counterUp.default;
				const callback = (entries) => {
					entries.forEach((entry) => {
						const el = entry.target;
						if (
							entry.isIntersecting &&
							!el.classList.contains("is-visible")
						) {
							counterUp(el, {
								duration: 2000,
								delay: 200,
							});
							el.classList.add("is-visible");
						}
					});
				};
				const IO = new IntersectionObserver(callback, { threshold: 1 });

				counterItem.forEach((counter) => {
					IO.observe(counter);
				});
			}
		}
	},
	setBackground: () => {
		setBackground.forEach((attributes) => {
			attributes.style.cssText = `
			background-image: url(${attributes.getAttribute("setBackground")});
			background-size: cover;
			background-position: center;
		  `;
		});
	},
	toggleItem: () => {
		$(".toggle-wrap .toggle-item").click(function () {
			if ($(this).hasClass("is-toggle")) {
				$(this).find(".article").slideUp();
				$(this).removeClass("is-toggle");
			} else {
				$(this).find(".article").slideDown();
				$(this).addClass("is-toggle");
			}
		});
	},
	filterItem: () => {
		$(".filter-display-wrap").each(function () {
			let x = 6;
			$(this).find(".row").slice(0, 6).show().css({
				display: "flex",
			});
			let size = $(this).find(".row").length;
			if (size < x || size === x) {
				$(this).find(".ajax-btn-wrap").addClass("disble-btn");
			}
			$(".filter-display-wrap")
				.find(".btn-secondary")
				.on("click", function (e) {
					e.preventDefault();
					x = x + 3;
					$(this)
						.parents(".filter-display-wrap")
						.find(".row")
						.slice(0, x)
						.slideDown()
						.css({
							display: "flex",
						});
					if (x >= size || size < x) {
						$(this).slideUp();
					}
				});
		});
		$(".filter-faq-wrap").each(function () {
			let x = 10;
			$(this).find(".toggle-item").slice(0, 10).show().css({
				display: "flex",
			});
			let size = $(this).find(".toggle-item").length;
			if (size < x || size === x) {
				$(this).find(".ajax-btn-wrap").addClass("disble-btn");
			}
			$(this)
				.find(".btn-secondary")
				.on("click", function (e) {
					e.preventDefault();
					x = x + 10;
					$(this)
						.parents(".filter-faq-wrap")
						.find(".toggle-item")
						.slice(0, x)
						.slideDown()
						.css({
							display: "flex",
						});
					if (x >= size || size < x) {
						$(this).slideUp();
					}
				});
		});
	},
	a11yUser: () => {
		const wrapForms = document.querySelectorAll(".wrap-form");

		wrapForms.forEach(function (form) {
			const submitInput = form.querySelector("input[type='submit']");
			const labelFor = submitInput.getAttribute("name");
			const labelSubmit = form.querySelector(".frm-btnwrap .label");
			if (labelSubmit !== null) {
				labelSubmit.textContent = "Submit Button";
				labelSubmit.setAttribute("for", labelFor);
			}
		});
	},
	hideElement: () => {
		if ($(window).width() < 1200.1) {
			const [search, menu] = [".searchbox", ".nav-primary-menu"].map(
				(selector) => document.querySelector(selector)
			);

			[search, menu].forEach((el) => (el.style.display = "flex"));
		}
	},
	figureImage: () => {
		$(".news-detail .fullcontent img").each(function (index, element) {
			let _this = $(element);
			let caption = _this.attr("title");
			if (caption) {
				let figure = $("<figure></figure>");
				_this.wrap(figure);

				let figcaption = $(`<figcaption>${caption}</figcaption>`);
				figcaption.insertAfter(_this);
			}
		});
	},

	expandContent: () => {
		$(".read-more-wrap").each(function (index) {
			$(this).addClass("read-instance-" + index);
			let $this = $(".read-instance-" + index);
			$this.each(function () {
				let height = $this.find(".article").outerHeight();
				console.log(height);
				if (height < 300) {
					$this.find(".btn-read-more").addClass("hide");
					console.log("hide");
				} else {
					$this.find(".article").css({
						height: "300",
					});
					console.log("limit");
				}
				let button = $this.find(".btn-read-more");
				let content = $this;
				button.on("click", function () {
					content.toggleClass("active");
				});
			});
		});
	},
	init: () => {
		generalFunction.toggleFunc();
		generalFunction.backTop();
		generalFunction.tabFunction();
		generalFunction.dropdownMenu();
		generalFunction.counterAnimate();
		generalFunction.setBackground();
		generalFunction.toggleItem();
		generalFunction.filterItem();
		generalFunction.hideElement();
		generalFunction.a11yUser();
		generalFunction.expandContent();
		generalFunction.figureImage();
	},
};

function swiperInit() {
	var primarySwiper = new Swiper(".primary-banner .swiper", {
		// Optional parameters
		slidesPerView: 1,
		observer: true,
		observeParents: true,
		preventInteractionOnTransition: true,
		speed: 1205,
		autoplay: false,
		lazy: {
			loadPrevNext: true,
		},
		loop: true,
		navigation: {
			nextEl: ".primary-banner .nav-arrow-next",
			prevEl: ".primary-banner .nav-arrow-prev",
		},
		pagination: {
			el: ".primary-banner  .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});

	let timer = null;

	primarySwiper.on("slideChangeTransitionEnd", function () {
		if (timer) {
			clearTimeout(timer); //cancel the previous timer.
			timer = null;
		}
		const currentSlide = primarySwiper.slides[primarySwiper.activeIndex];
		const currentSlideHasVideo = currentSlide.querySelector("video");
		if (currentSlideHasVideo) {
			const videoElement = currentSlide.querySelector("video");
			videoElement.play();
			videoElement.addEventListener("ended", function () {
				primarySwiper.slideToClosest();
			});
		} else {
			timer = setTimeout(() => {
				primarySwiper.slideNext();
			}, 3000);
		}
	});

	const primaryBannerVideo = document.querySelectorAll(
		".primary-banner-video"
	);
	primaryBannerVideo.forEach(function (videoElement) {
		videoElement.addEventListener("ended", function () {
			primarySwiper.slideNext();
		});
		videoElement.addEventListener("click", function (event) {
			if (this.paused) {
				this.play();
				this.parentNode.classList.remove("pause");
			} else {
				this.pause();
				this.parentNode.classList.add("pause");
			}
		});
	});

	$(".home-collection-swiper .swiper").each(function (index) {
		var $this = $(this);
		$this.addClass("collection-instance-" + index);
		$this
			.parent()
			.find(".nav-prev")
			.addClass("prev-col-" + index);
		$this
			.parent()
			.find(".nav-next")
			.addClass("next-col-" + index);

		var swiper = new Swiper(".collection-instance-" + index, {
			speed: 1205,
			loop: false,
			autoplay: {
				delay: 4000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},

			observer: true,
			spaceBetween: 20,
			observeParents: true,
			preventInteractionOnTransition: true,
			breakpoints: {
				200: {
					slidesPerView: 2,
				},
				576: {
					slidesPerView: 3,
				},
				769: {
					slidesPerView: 3.9,
				},
			},
			navigation: {
				nextEl: ".next-col-" + index,
				prevEl: ".prev-col-" + index,
			},
		});
	});

	var productThumb = new Swiper(".swiper-product-thumb .swiper", {
		spaceBetween: 10,
		breakpointsInverse: true,
		breakpoints: {
			200: {
				slidesPerView: 3,
			},
			576: {
				slidesPerView: 4,
			},
			769: {
				slidesPerView: 5,
			},
			1360: {
				slidesPerView: 4,
			},
		},
		freeMode: true,
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
	});

	var productMain = new Swiper(".swiper-product-main .swiper", {
		// Optional parameters
		spaceBetween: 30,
		slidesPerView: 1,
		speed: 1205,
		breakpointsInverse: true,
		autoplay: {
			delay: 3500,
		},
		thumbs: {
			swiper: productThumb,
		},
		pagination: {
			el: ".swiper-pagination",
			type: "fraction",
		},

		navigation: {
			nextEl: ".swiper-product-thumb .next-nav",
			prevEl: ".swiper-product-thumb .prev-nav",
		},
	});
	$(".single-swiper .swiper").each(function (index) {
		var $this = $(this);
		$this.addClass("single-instance-" + index);
		$this
			.parent()
			.find(".prev")
			.addClass("prev-single-" + index);
		$this
			.parent()
			.find(".next")
			.addClass("next-single-" + index);
		$this
			.parent()
			.find(".swiper-pagination")
			.addClass("pagination-instance-" + index);

		var swiper = new Swiper(".single-instance-" + index, {
			loop: false,
			speed: 1205,
			autoplay: {
				delay: 4000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			slidesPerView: 1,
			preventInteractionOnTransition: true,
			navigation: {
				nextEl: ".next-single-" + index,
				prevEl: ".prev-single-" + index,
			},
			pagination: {
				el: ".pagination-instance-" + index,
				type: "bullets",
				clickable: true,
			},
		});
	});
	$(".trinity-swiper .swiper").each(function (index) {
		var $this = $(this);
		$this.addClass("tri-instance-" + index);
		$this
			.parent()
			.find(".prev")
			.addClass("prev-tri-" + index);
		$this
			.parent()
			.find(".next")
			.addClass("next-tri-" + index);

		var swiper = new Swiper(".tri-instance-" + index, {
			speed: 1205,
			loop: true,
			autoplay: {
				delay: 5000,
			},

			observer: true,
			spaceBetween: 30,
			observeParents: true,
			preventInteractionOnTransition: true,
			breakpoints: {
				200: {
					slidesPerView: 1,
					spaceBetween: 10,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 3,
					spaceBetween: 20,
				},
			},
			navigation: {
				nextEl: ".next-tri-" + index,
				prevEl: ".prev-tri-" + index,
			},
		});
	});

	$(".four-swiper .swiper").each(function (index) {
		var $this = $(this);
		$this.addClass("instance-four" + index);
		$this
			.parent()
			.find(".prev")
			.addClass("prev-four-" + index);
		$this
			.parent()
			.find(".next")
			.addClass("next-four-" + index);

		var swiper = new Swiper(".instance-four" + index, {
			speed: 1205,
			loop: false,
			autoplay: {
				delay: 4000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			preventInteractionOnTransition: true,
			breakpoints: {
				200: {
					slidesPerView: 1,
					spaceBetween: 10,
				},
				420: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1280: {
					slidesPerView: 4,
				},
			},
			navigation: {
				nextEl: ".next-four-" + index,
				prevEl: ".prev-four-" + index,
			},
		});
	});

	$(".five-swiper .swiper").each(function (index) {
		var $this = $(this);
		$this.addClass("instance-five-" + index);
		$this
			.parent()
			.find(".prev")
			.addClass("prev-five-" + index);
		$this
			.parent()
			.find(".next")
			.addClass("next-five-" + index);

		var swiper = new Swiper(".instance-five-" + index, {
			speed: 1205,
			loop: false,
			autoplay: {
				delay: 4000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			preventInteractionOnTransition: true,
			breakpoints: {
				200: {
					slidesPerView: 2,
					spaceBetween: 10,
				},
				420: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1280: {
					slidesPerView: 4,
				},
				1360: {
					slidesPerView: 5,
				},
			},
			navigation: {
				nextEl: ".next-five-" + index,
				prevEl: ".prev-five-" + index,
			},
		});
	});

	var swiper = new Swiper(".partner-swiper .swiper", {
		speed: 1205,
		loop: false,
		autoplay: {
			delay: 4000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		observer: true,
		spaceBetween: 10,
		observeParents: true,
		preventInteractionOnTransition: true,
		breakpoints: {
			200: {
				slidesPerView: 3,
			},
			420: {
				slidesPerView: 3,
			},
			769: {
				slidesPerView: 4,
			},
		},
		pagination: {
			el: ".partner-swiper .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});

	var swiperGrid = new Swiper(".swiper-grid-2 .swiper", {
		autoplay: {
			delay: 4000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		observer: true,
		spaceBetween: 30,
		observeParents: true,

		breakpoints: {
			200: {
				slidesPerView: 1,
			},
			460: {
				slidesPerView: 2,
			},
			768: {
				slidesPerView: 3,
			},
			1200: {
				slidesPerView: 2,
				grid: {
					rows: 2,
					fill: "row",
				},
			},
		},

		navigation: {
			nextEl: ".swiper-grid-2 .next",
			prevEl: ".swiper-grid-2 .prev",
		},
		pagination: {
			el: ".swiper-grid-2 .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});
}

function mappingFunc() {
	new MappingListener({
		selector: ".searchbox",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".search-wrap",
		desktopMethod: "appendTo",
		breakpoint: 1200.1,
	}).watch();
	new MappingListener({
		selector: ".header-bottom",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".header-top",
		desktopMethod: "insertAfter",
		breakpoint: 1200.1,
	}).watch();
	new MappingListener({
		selector: ".language-wrap",
		mobileWrapper: ".mobile-top-nav",
		mobileMethod: "appendTo",
		desktopWrapper: ".search-wrap",
		desktopMethod: "insertBefore",
		breakpoint: 768.1,
	}).watch();
	new MappingListener({
		selector: ".account-wrap",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".search-wrap",
		desktopMethod: "insertAfter",
		breakpoint: 768.1,
	}).watch();
}
